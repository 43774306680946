.account {
  display: flex;
  flex: 1 0;
  padding-left: 8px;
  cursor: default;
  height: 40px;
  overflow: hidden;
}
.avatar {
  height: 30px;
  width: 30px;
  background-size: cover;
}
.names {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.title {
  text-overflow: ellipsis;
  overflow: hidden;
}
.subtitle {
  color: #555555;
  text-overflow: ellipsis;
  overflow: hidden;
}
