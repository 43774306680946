.selectContainer {
  position: relative;
  margin: 4px 0;
}

.selectBox {
  padding: 6px;
  border: solid 1px #CCCCCC;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.selectBox:hover {
  background: #EEE;
}
.selectBox:after {
  content: '\25be';
  margin: 4px;
  color: #555;
  display: block;
}

.selectDropdown {
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  padding: 6px;
  box-shadow: 0px 3px 5px 0px #0000008a;
  border-radius: 4px;
  z-index: 10;
}
.dropdownItem:hover {
  background: #EFEFEF;
}

.assetElement {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  flex: 1 0;
  cursor: default;
}
.assetName {
  flex: 1 0;
  font-size: 24px;
}
.assetBalance {
  font-size: 18px;
  color: #999;
}

